import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19cbc9c6 = () => interopDefault(import('../pages/empty.vue' /* webpackChunkName: "pages/empty" */))
const _4faffcd0 = () => interopDefault(import('../pages/ie-is-not-allowed-here.vue' /* webpackChunkName: "pages/ie-is-not-allowed-here" */))
const _3e4d22cb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7b014949 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _0f3123b0 = () => interopDefault(import('../pages/_lang/brands/index.vue' /* webpackChunkName: "pages/_lang/brands/index" */))
const _5f277288 = () => interopDefault(import('../pages/_lang/cart/index.vue' /* webpackChunkName: "pages/_lang/cart/index" */))
const _6180f8e2 = () => interopDefault(import('../pages/_lang/checkout/index.vue' /* webpackChunkName: "pages/_lang/checkout/index" */))
const _d3be3fc8 = () => interopDefault(import('../pages/_lang/compare.vue' /* webpackChunkName: "pages/_lang/compare" */))
const _6ff6a714 = () => interopDefault(import('../pages/_lang/login-operatore.vue' /* webpackChunkName: "pages/_lang/login-operatore" */))
const _2668357e = () => interopDefault(import('../pages/_lang/pages/index.vue' /* webpackChunkName: "pages/_lang/pages/index" */))
const _aa13a962 = () => interopDefault(import('../pages/_lang/productfinder/index.vue' /* webpackChunkName: "pages/_lang/productfinder/index" */))
const _95ed2100 = () => interopDefault(import('../pages/_lang/products/index.vue' /* webpackChunkName: "pages/_lang/products/index" */))
const _251bd8e6 = () => interopDefault(import('../pages/_lang/stories/index.vue' /* webpackChunkName: "pages/_lang/stories/index" */))
const _2d27c4b2 = () => interopDefault(import('../pages/_lang/user/index.vue' /* webpackChunkName: "pages/_lang/user/index" */))
const _0a261c50 = () => interopDefault(import('../pages/_lang/checkout/add-address.vue' /* webpackChunkName: "pages/_lang/checkout/add-address" */))
const _8c2aae9c = () => interopDefault(import('../pages/_lang/checkout/billing-address.vue' /* webpackChunkName: "pages/_lang/checkout/billing-address" */))
const _cf92ebd4 = () => interopDefault(import('../pages/_lang/checkout/payment.vue' /* webpackChunkName: "pages/_lang/checkout/payment" */))
const _542800a7 = () => interopDefault(import('../pages/_lang/checkout/personal-data.vue' /* webpackChunkName: "pages/_lang/checkout/personal-data" */))
const _052198d6 = () => interopDefault(import('../pages/_lang/checkout/thankyou.vue' /* webpackChunkName: "pages/_lang/checkout/thankyou" */))
const _071540f6 = () => interopDefault(import('../pages/_lang/user/logout.vue' /* webpackChunkName: "pages/_lang/user/logout" */))
const _1fb205e3 = () => interopDefault(import('../pages/_lang/user/orders/index.vue' /* webpackChunkName: "pages/_lang/user/orders/index" */))
const _38535fbe = () => interopDefault(import('../pages/_lang/user/profile.vue' /* webpackChunkName: "pages/_lang/user/profile" */))
const _f1e0d16e = () => interopDefault(import('../pages/_lang/user/orders/_orderId.vue' /* webpackChunkName: "pages/_lang/user/orders/_orderId" */))
const _129e6640 = () => interopDefault(import('../pages/_lang/brands/_slug.vue' /* webpackChunkName: "pages/_lang/brands/_slug" */))
const _24b19436 = () => interopDefault(import('../pages/_lang/pages/_slug.vue' /* webpackChunkName: "pages/_lang/pages/_slug" */))
const _d87182dc = () => interopDefault(import('../pages/_lang/products/_aslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/index" */))
const _28891b76 = () => interopDefault(import('../pages/_lang/stories/_slug.vue' /* webpackChunkName: "pages/_lang/stories/_slug" */))
const _5d39bcfe = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/index" */))
const _3e194df3 = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/_cslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/_cslug/index" */))
const _94322782 = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/_cslug/_dslug.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/_cslug/_dslug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/empty",
    component: _19cbc9c6,
    name: "empty"
  }, {
    path: "/ie-is-not-allowed-here",
    component: _4faffcd0,
    name: "ie-is-not-allowed-here"
  }, {
    path: "/",
    component: _3e4d22cb,
    name: "index"
  }, {
    path: "/:lang",
    component: _7b014949,
    name: "lang"
  }, {
    path: "/:lang/brands",
    component: _0f3123b0,
    name: "lang-brands"
  }, {
    path: "/:lang/cart",
    component: _5f277288,
    name: "lang-cart"
  }, {
    path: "/:lang/checkout",
    component: _6180f8e2,
    name: "lang-checkout"
  }, {
    path: "/:lang/compare",
    component: _d3be3fc8,
    name: "lang-compare"
  }, {
    path: "/:lang/login-operatore",
    component: _6ff6a714,
    name: "lang-login-operatore"
  }, {
    path: "/:lang/pages",
    component: _2668357e,
    name: "lang-pages"
  }, {
    path: "/:lang/productfinder",
    component: _aa13a962,
    name: "lang-productfinder"
  }, {
    path: "/:lang/products",
    component: _95ed2100,
    name: "lang-products"
  }, {
    path: "/:lang/stories",
    component: _251bd8e6,
    name: "lang-stories"
  }, {
    path: "/:lang/user",
    component: _2d27c4b2,
    name: "lang-user"
  }, {
    path: "/:lang/checkout/add-address",
    component: _0a261c50,
    name: "lang-checkout-add-address"
  }, {
    path: "/:lang/checkout/billing-address",
    component: _8c2aae9c,
    name: "lang-checkout-billing-address"
  }, {
    path: "/:lang/checkout/payment",
    component: _cf92ebd4,
    name: "lang-checkout-payment"
  }, {
    path: "/:lang/checkout/personal-data",
    component: _542800a7,
    name: "lang-checkout-personal-data"
  }, {
    path: "/:lang/checkout/thankyou",
    component: _052198d6,
    name: "lang-checkout-thankyou"
  }, {
    path: "/:lang/user/logout",
    component: _071540f6,
    name: "lang-user-logout"
  }, {
    path: "/:lang/user/orders",
    component: _1fb205e3,
    name: "lang-user-orders"
  }, {
    path: "/:lang/user/profile",
    component: _38535fbe,
    name: "lang-user-profile"
  }, {
    path: "/:lang/user/orders/:orderId",
    component: _f1e0d16e,
    name: "lang-user-orders-orderId"
  }, {
    path: "/:lang/brands/:slug",
    component: _129e6640,
    name: "lang-brands-slug"
  }, {
    path: "/:lang/pages/:slug",
    component: _24b19436,
    name: "lang-pages-slug"
  }, {
    path: "/:lang/products/:aslug",
    component: _d87182dc,
    name: "lang-products-aslug"
  }, {
    path: "/:lang/stories/:slug",
    component: _28891b76,
    name: "lang-stories-slug"
  }, {
    path: "/:lang/products/:aslug/:bslug",
    component: _5d39bcfe,
    name: "lang-products-aslug-bslug"
  }, {
    path: "/:lang/products/:aslug/:bslug/:cslug",
    component: _3e194df3,
    name: "lang-products-aslug-bslug-cslug"
  }, {
    path: "/:lang/products/:aslug/:bslug/:cslug/:dslug",
    component: _94322782,
    name: "lang-products-aslug-bslug-cslug-dslug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
